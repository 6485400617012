section {
  display: flex;
  align-items: center;
  justify-content: center;
}

form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  margin: 50px;
  background-color: #12539a;
  border-radius: 5px;
  width: 400px;
  max-width: 400px;
}

.box-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background-color: #12539a;
  border-radius: 5px;
  padding: 20px;
  width: 400px;
  max-width: 400px;
  margin: auto !important;
}

.form-name {
  font-family: "Nunito-Bold";
  color: #fff;
  font-size: 26px;
  margin: 1rem 0;
}

label {
  font-family: "Nunito-Bold";
  color: #fff;
  font-size: 18px;
}

.label-with-icon {
  display: flex;
  gap: 5px;
  font-size: 18px;
}

.trustbox-div {
  display: flex;
  align-items: center;
  padding: 10px 0 0;
  gap: 5px;
}

input[type="text"],
input[type="password"] {
  padding: 10px;
  font-size: 18px;
  font-family: "Nunito-Regular";
  border-radius: 10px;
}

input[type="checkbox"] {
  width: 20px;
  height: 20px;
}

button {
  padding: 10px;
  font-size: 18px;
  font-family: "Nunito-Bold";
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 0;
  cursor: pointer;
}

.link {
  display: block;
  font-family: "Nunito-Regular";
  color: #fff;
  font-size: 20px;
  margin: 10px 0;
}

.text {
  font-family: "Nunito-Regular";
  color: #fff;
  font-size: 20px;
  margin: 10px 0;
}

.error-info {
  background: black;
  color: white;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
}

.error-message {
  background: pink;
  color: crimson;
  font-family: "Nunito-Bold";
  padding: 10px;
  border-radius: 5px;
  margin: 0;
  font-size: 18px;
}

.success-message {
  background: greenyellow;
  color: green;
  font-family: "Nunito-Bold";
  padding: 10px;
  border-radius: 5px;
  margin: 0;
  font-size: 18px;
}

table {
  width: 100%;
}

.table-data-div {
  font-family: "Nunito-Bold";
  color: #fff;
  margin: 10px 0;
  height: 200px;
  overflow-y: scroll;
}

.loader-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.remove {
  display: none;
}

@media screen and ((max-width: 600px)) {
  form {
    max-width: 80%;
    margin: 50px 0;
  }

  section {
    align-items: normal;
  }

  .box-container {
    max-width: 80%;
    margin: 0;
  }
}
